import React, { useState, useRef, useEffect } from 'react';

const WordSearchBoard = () => {
  const initialBoard = [
    ['O','S','L','O','E','I','H','N','W','O','V','C'],
    ['E','P','O','C','R','Z','W','I','A','I','T','W'],
    ['O','R','A','A','O','T','O','U','V','B','P','O'],
    ['N','Y','A','C','A','I','C','G','E','R','X','I'],
    ['I','O','O','A','P','R','A','N','S','U','G','T'],
    ['E','G','O','U','R','B','E','R','N','E','I','O'],
    ['U','H','R','S','L','A','A','P','R','Y','A','U'],
    ['N','U','E','Y','E','R','C','F','A','E','S','R'],
    ['L','R','A','L','T','O','U','S','Z','O','O','A'],
    ['X','T','R','R','U','M','I','R','E','L','C','A'],
    ['Q','S','O','L','U','T','L','O','N','Q','W','A'],
    ['I','U','G','O','O','B','M','I','B','R','P','R']
  ];

  const [board, setBoard] = useState(initialBoard);
  const [wordList, setWordList] = useState(['CAR', 'YES', 'SOLUTION']);
  const [dragStart, setDragStart] = useState(null);
  const [dragOver, setDragOver] = useState(null);
  const [cellSize, setCellSize] = useState(32);
  const [animatingCells, setAnimatingCells] = useState([]);
  const [fallingCells, setFallingCells] = useState([]);
  const [matchedWord, setMatchedWord] = useState(null);
  const containerRef = useRef(null);
  const boardRef = useRef(null);

  useEffect(() => {
    const fetchWordList = async () => {
      try {
        const response = await fetch('wordlist.10000.txt');
        const text = await response.text();
        const words = text.split('\n');
        const filteredWords = words
          .filter(word => word.length >= 3 && word.length <= 12)
          .sort((a, b) => b.length - a.length)
          .map(word => word.toUpperCase());
        setWordList(prevList => [...new Set([...prevList, ...filteredWords])]);
      } catch (error) {
        console.error('Error fetching word list:', error);
      }
    };

    fetchWordList();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && boardRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const titleHeight = containerRef.current.querySelector('h2').offsetHeight;
        const availableHeight = containerHeight - titleHeight - 32;
        const maxBoardSize = Math.min(containerWidth, availableHeight);
        const newCellSize = Math.floor(maxBoardSize / 12) - 2;
        setCellSize(newCellSize);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (matchedWord) {
      const timer = setTimeout(() => {
        setMatchedWord(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [matchedWord]);

  const isAdjacent = (start, end) => {
    const rowDiff = Math.abs(start.row - end.row);
    const colDiff = Math.abs(start.col - end.col);
    return (rowDiff <= 1 && colDiff <= 1) && (rowDiff + colDiff > 0);
  };

  const checkForWords = (newBoard, row1, col1, row2, col2) => {
    const matches = [];
    const matchedWords = [];

    const checkLine = (startRow, startCol, direction) => {
      const line = [];
      for (let i = 0; i < 12; i++) {
        line.push(newBoard[startRow * direction[1] + i * direction[0]][startCol * direction[0] + i * direction[1]]);
      }
      const lineStr = line.join('');
      for (const word of wordList) {
        let index = lineStr.indexOf(word);
        let pos = startRow * direction[0] + startCol * direction[1];
        if (index !== -1 && index <= pos && pos < index + word.length) {
          for (let i = 0; i < word.length; i++) {
            matches.push([startRow * direction[1] + (index + i) * direction[0], startCol * direction[0] + (index + i) * direction[1]]);
          }
          index = lineStr.indexOf(word, index + 1);
          matchedWords.push(word);
          break;
        }
        // Check for reverse word
        const reverseWord = word.split('').reverse().join('');
        index = lineStr.indexOf(reverseWord);
        if (index !== -1 && index <= pos && pos < index + word.length) {
          for (let i = 0; i < reverseWord.length; i++) {
            matches.push([startRow * direction[1] + (index + i) * direction[0], startCol * direction[0] + (index + i) * direction[1]]);
          }
          index = lineStr.indexOf(reverseWord, index + 1);
          matchedWords.push(word);
          break;
        }
      }
    };

    // Check lines around the swapped cells
    [{ row: row1, col: col1 }, { row: row2, col: col2 }].forEach(({ row, col }) => {
      checkLine(row, col, [0, 1]);
      checkLine(row, col, [1, 0]);
    });

    const uniqueMatches = Array.from(new Set(matches.map(JSON.stringify)), JSON.parse);
    const uniqueMatchedWords = Array.from(new Set(matchedWords));

    return { matches: uniqueMatches, matchedWords: uniqueMatchedWords };
  };

  const getRandomLetter = () => {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26));
  };

  const handleDragStart = (row, col) => {
    setDragStart({ row, col });
  };

  const handleDragEnter = (row, col) => {
    if (dragStart && isAdjacent(dragStart, { row, col })) {
      setDragOver({ row, col });
    }
  };

  const handleDragEnd = () => {
    if (dragStart && dragOver && isAdjacent(dragStart, dragOver)) {
      const newBoard = board.map(row => [...row]);
      [newBoard[dragStart.row][dragStart.col], newBoard[dragOver.row][dragOver.col]] =
      [newBoard[dragOver.row][dragOver.col], newBoard[dragStart.row][dragStart.col]];

      const { matches, matchedWords } = checkForWords(newBoard, dragStart.row, dragStart.col, dragOver.row, dragOver.col);
      if (matches.length > 0) {
        const newFallingCells = [];
        const columnsToFill = new Set();

        setMatchedWord(matchedWords.join(', '));

        matches.forEach(([row, col]) => {
          columnsToFill.add(col);
          for (let i = row; i > 0; i--) {
            newFallingCells.push({
              from: [i - 1, col],
              to: [i, col],
              letter: newBoard[i - 1][col]
            });
            newBoard[i][col] = newBoard[i - 1][col];
          }
          newBoard[0][col] = getRandomLetter(); // Fill top with random letter
        });

        setFallingCells(newFallingCells);

        // Update the board immediately
        const finalBoard = newBoard.map((row, rowIndex) =>
          row.map((cell, colIndex) =>
            rowIndex === 0 && columnsToFill.has(colIndex) ? getRandomLetter() : cell
          )
        );
        setBoard(finalBoard);

        // Clear falling cells after animation
        setTimeout(() => {
          setFallingCells([]);
        }, 500);
      } else {
        // Rollback animation
        setAnimatingCells([dragStart, dragOver]);
        setTimeout(() => {
          setAnimatingCells([]);
          setBoard(board);
        }, 500);
      }
    }
    setDragStart(null);
    setDragOver(null);
  };

  const handleTouchStart = (row, col) => {
    setDragStart({ row, col });
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (dragStart) {
      const touch = e.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (element && element.dataset.row && element.dataset.col) {
        const row = parseInt(element.dataset.row);
        const col = parseInt(element.dataset.col);
        if (isAdjacent(dragStart, { row, col })) {
          setDragOver({ row, col });
        }
      }
    }
  };

  const handleTouchEnd = () => {
    handleDragEnd();
  };

  const getCellClassName = (rowIndex, colIndex) => {
    let className = "flex items-center justify-center cursor-move select-none transition-all duration-300 border border-gray-300";

    if (dragStart && dragStart.row === rowIndex && dragStart.col === colIndex) {
      className += " scale-110 shadow-lg z-10 bg-yellow-200";
    }

    if (dragOver && dragOver.row === rowIndex && dragOver.col === colIndex) {
      className += " scale-110 shadow-lg z-10 bg-green-200";
    }

    if (animatingCells.some(cell => cell.row === rowIndex && cell.col === colIndex)) {
      className += " animate-bounce";
    }

    return className;
  };

  const MatchedWordDisplay = ({ word }) => {
    if (!word) return null;
    return (
      <div className="fixed inset-0 flex items-center justify-center pointer-events-none">
        <div className="bg-green-500 text-white text-4xl font-bold py-2 px-4 rounded-lg animate-fade-out">
          {word}
        </div>
      </div>
    );
  };

  return (
    <div ref={containerRef} className="fixed inset-0 p-4 flex flex-col items-center justify-center">
      <h2 className="text-2xl font-bold mb-4">Word Search Puzzle</h2>
      <div className="mb-4">
        <strong>Words to find:</strong> {wordList.slice(0, 10).join(', ')}
        {wordList.length > 10 ? ` ... and ${wordList.length - 10} more` : ''}
      </div>
      <div
        ref={boardRef}
        className="inline-block border border-gray-300"
        onMouseUp={handleDragEnd}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className="flex">
            {row.map((letter, colIndex) => {
              const className = getCellClassName(rowIndex, colIndex);
              const fallingCell = fallingCells.find(cell => cell.to[0] === rowIndex && cell.to[1] === colIndex);
              const style = {
                width: `${cellSize}px`,
                height: `${cellSize}px`,
                fontSize: `${cellSize * 0.5}px`,
                transition: fallingCell ? 'transform 0.5s ease-in' : 'none',
                transform: fallingCell ? `translateY(${cellSize}px)` : 'none'
              };

              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={className}
                  style={style}
                  onMouseDown={() => handleDragStart(rowIndex, colIndex)}
                  onMouseEnter={() => handleDragEnter(rowIndex, colIndex)}
                  onTouchStart={() => handleTouchStart(rowIndex, colIndex)}
                  data-row={rowIndex}
                  data-col={colIndex}
                >
                  {fallingCell ? fallingCell.letter : letter}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <MatchedWordDisplay word={matchedWord} />
    </div>
  );
};

export default WordSearchBoard;
