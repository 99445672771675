import React from 'react';
import WordSearchBoard from './WordSearchBoard';

function App() {
  return (
    <div className="App">
      <WordSearchBoard />
    </div>
  );
}

export default App;
